<template>
    <vx-card :title="(this.id ? 'Edit' : 'Create') + ' Purchase Order Return'">
        <div class="vx-row mb-6">
            <vs-button 
                class="ml-4 mt-2" 
                color="danger" 
                icon-pack="feather" 
                icon="icon-arrow-left"
                @click="handleBack()"
            >Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <h4>Selection</h4>
                <hr>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>Supplier return type</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <v-select
                            :filterable="true"
                            :options="returnType"
                            v-model="selectedReturnType"
                            label="name"
                            :clearable="false"
                            :disabled="(this.id > 0) ? true : false"
                            @input="val => { onChangeReturnType(val); }"
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>PO Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <v-select 
                            :filterable="false"
                            :disabled="!selectedReturnType.value"
                            :options="optionPO"
                            v-model="purchase_order"
                            label="code"
                            :clearable="false"
                            @input="val => { onChangePoNumber(val); }"
                            @search="(search, loading) => { onSearchPoNumber(search, loading); }"
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>Supplier Code</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <vs-input
                            type="text"
                            :value="supplier ? supplier.code : ''"
                            disabled
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>Supplier Name</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <v-select 
                            :filterable="true"
                            :disabled="selectedReturnType.value || (this.documentType == 'import - PO Return Begbal' ? true : false)"
                            :options="optionSupplier"
                            v-model="supplier"
                            :clearable="false"
                            label="name"
                            @input="val => { onChangeSupplierCode(val); }"
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>Supplier Address</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <vs-input
                            type="text"
                            :value="supplier ? supplier.address : ''"
                            disabled
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>GR Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <v-select
                            multiple
                            :filterable="true"
                            :disabled="!selectedReturnType.value"
                            :options="optionGR"
                            v-model="gr_number"
                            label="code"
                            @input="val => { onChangeGRNumber(val); }"
                        >
                        </v-select>
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2" v-if="selectedReturnType.value">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>Tax Rate</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <vs-input
                            type="text"
                            :value="tax ? tax.name : ''"
                            disabled
                            class="w-full"
                            v-if="selectedReturnType.value"
                        />
                        <v-select
                            :filterable="true"
                            :options="optionTax"
                            v-model="tax"
                            label="name"
                            :clearable="false"
                            @input="val => { onChangeTax(val); }"
                            v-else
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <label>Attachment<small style="color: red"> (only: jpg, jpeg, png, heic, mp4)</small></label>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <input 
                            id="fileInput" 
                            name="file" 
                            class="w-full inputx" 
                            type="file" 
                            ref="file" 
                            multiple="multiple"
                            accept=".jpg, .jpeg, .png, .pdf, .heic, .mp4"
                            @change="onChangeAttachment"
                            :disabled="this.documentType == 'import - PO Return Begbal' ? true : false"
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span></span>
                    </div>
                </div>
                <vs-divider style="margin-left: 0%">
                    List Attachment
                </vs-divider>
                <div class="mb-6 vx-row pt-2" style="margin-left: 0%" v-if="this.documentType != 'import - PO Return Begbal'">
                    <table class="vs-table vs-table--tbody-table">
                        <template>
                            <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile" v-for="(tr, i) in fileAttachment">
                                <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                <td>
                                    <img :src="urlFile(tr)" style="width: 100px; height: 100px; object-fit: cover;" class="max-w-sm" @click="openPreview(i)" alt="">
                                </td>
                                <td class="td vs-table--td">
                                    <vx-tooltip text="Download" v-if="tr.PathFile != ''">
                                        <vs-button 
                                            type="line" 
                                            icon-pack="feather" 
                                            icon="icon-download" 
                                            :disabled="!tr.PathFile"
                                            @click.stop="download(tr)"
                                        />
                                    </vx-tooltip>
                                </td>
                                <td class="td vs-table--td">
                                    <template>
                                        <vx-tooltip text="Delete">
                                            <vs-button 
                                                type="line" 
                                                icon-pack="feather" 
                                                icon="icon-trash"
                                                @click.stop="handleDeleteAttachment(i)"
                                            />
                                        </vx-tooltip>
                                    </template>
                                </td>
                            </tr>
                        </template>
                    </table>
                </div>
                <vue-easy-lightbox
                    :visible="visible"
                    :imgs="images"
                    :index="index"
                    @hide="handleHide"
                />
                <br>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                    <h4>Supplier Return Data</h4>
                    <hr>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>SR Number</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <vs-input
                                type="text"
                                :value="code"
                                disabled
                                class="w-full"
                            />
                        </div>
                    </div>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>Source</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <vs-input
                                type="text"
                                :value="documentType"
                                disabled
                                class="w-full"
                            />
                        </div>
                    </div>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>Created At</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <vs-input
                                type="text"
                                :value="created_at"
                                disabled
                                class="w-full"
                            />
                        </div>
                    </div>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>Created By</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <vs-input
                                type="text"
                                :value="created_by"
                                disabled
                                class="w-full"
                            />
                        </div>
                    </div>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>Description</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <vs-input
                                type="text"
                                v-model="description"
                                class="w-full"
                            />
                        </div>
                    </div>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>Warehouse</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <vs-input
                                type="text"
                                :value="warehouse_label ? warehouse_label : ''"
                                disabled
                                class="w-full"
                                v-if="selectedReturnType.value"
                            />
                            <v-select
                                :filterable="true"
                                :options="optionWarehouse"
                                v-model="warehouse"
                                label="name"
                                :clearable="false"
                                :disabled="this.documentType == 'import - PO Return Begbal' ? true : false"
                                @input="val => { onChangeWarehouse(val); }"
                                v-else
                            />
                        </div>
                    </div>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>Return Date</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <datepicker 
                                format="yyyy-MM-dd" 
                                :disabled-dates="disabledDates" 
                                name="date" 
                                v-model="date"
                                class="w-full" 
                                placeholder="Select Date"
                                :disabled="this.documentType == 'import - PO Return Begbal' ? true : false"
                                :cleared="() => { }">
                            </datepicker>
                        </div>
                    </div>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>Note</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <vs-textarea v-model="note" width="100%" />
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
        <!-- ... -->
        <div class="mb-6 vx-row">
            <div class="vx-col w-full">
                <table class="table table-bordered" width="100%">
                    <thead class="vs-table--thead" v-if="!selectedReturnType.value">
                        <tr>
                            <th width="5%">Add Line</th>
                            <th width="15%">SKU Description</th>
                            <th width="5%">Tax Rate</th>
                            <th width="5%">Returned Qty</th>
                            <th width="6%">HU</th>
                            <th width="11%">Price</th>
                            <th width="10%">Batch</th>
                            <th width="15%">Warehouse Area</th>
                            <th width="15%">Reason</th>
                            <th width="5%">Add Row</th>
                        </tr>
                    </thead>

                    <thead class="vs-table--thead" v-if="selectedReturnType.value">
                        <tr>
                            <th width="5%">Remove Line</th>
                            <th width="20%">SKU Description</th>
                            <th width="5%">Qty</th>
                            <th width="5%">Returned Qty</th>
                            <th width="10%">HU</th>
                            <th width="7%">Price</th>
                            <th width="13%">Batch / Exp.Date</th>
                            <th width="15%">Warehouse Area</th>
                            <th width="15%">Reason</th>
                            <th width="5%">Add Row</th>
                        </tr>
                    </thead>

                    <tbody v-if="!selectedReturnType.value">
                        <template v-for="(skuLine, index) in skuLinesNoPo">
                            <tr style="padding-bottom: 5px" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="skuLine.putaway_lines.length">
                                    <div class="vx-input-group flex mx-auto">
                                        <vs-button
                                            v-if="index == 0"
                                            @click.stop="addLine(index)"
                                            size="small"
                                            color="success"
                                            icon-pack="feather"
                                            icon="icon-plus"
                                            title="Add Lines"
                                            :disabled="skuLine.disabledLine"
                                        />
                                        <vs-button
                                            v-if="index > 0"
                                            @click.stop="removeLine(index)"
                                            size="small"
                                            color="danger"
                                            icon-pack="feather"
                                            icon="icon-trash"
                                            title="Remove Lines"
                                            :disabled="skuLine.disabledLine"
                                        />
                                    </div>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="skuLine.putaway_lines.length">
                                    <v-select
                                        v-if="renderSku"
                                        :filterable="false"
                                        :options="skuLine.optionSKU"
                                        :value="skuLine.label ? skuLine.label : skuLine.sku_code"
                                        label="label"
                                        class="w-full"
                                        :clearable="false"
                                        :disabled="skuLine.disabledLine"
                                        @input="val => { onChangeSku(index, val) }"
                                        @search="(search, loading) => { onSearchSku(search, loading, index); }"
                                    ></v-select>
                                </td>

                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="skuLine.putaway_lines.length">
                                    <v-select
                                        v-if="renderTax"
                                        :filterable="true"
                                        :options="skuLine.optionTax"
                                        :value="skuLine.skuTax ? skuLine.skuTax.name : ''"
                                        label="name"
                                        :clearable="false"
                                        :disabled="skuLine.disabledLine"
                                        @input="val => { onChangeTaxSku(index, val); }"
                                    />
                                </td>

                                <!-- first putaway lines -->
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input
                                        type="number"
                                        v-model="skuLine.putaway_lines[0].return_qty"
                                        class="w-full"
                                        :min="0"
                                        :disabled="skuLine.disabledLine"
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="true"
                                        :options="skuLine.option_unit"
                                        v-model="skuLine.putaway_lines[0].selected_unit"
                                        label="name"
                                        class="w-full"
                                        :clearable="false"
                                        :disabled="skuLine.disabledLine"
                                        @input="val => { onChangeHU(index, 0, val) }"
                                    ></v-select>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input
                                        type="text"
                                        :value="skuLine.putaway_lines[0].price"
                                        disabled
                                        class="w-full"
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="true"
                                        :options="skuLine.optionBatch"
                                        :value="skuLine.putaway_lines[0].batch"
                                        :clearable="false"
                                        class="w-full"
                                        label="batch_external"
                                        @input="val => { onChangeBatch(index, 0, val) }"
                                    />
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="true"
                                        :options="skuLine.putaway_lines[0].optionWarehouseArea"
                                        :value="skuLine.putaway_lines[0].warehouse_area"
                                        :clearable="false"
                                        class="w-full"
                                        label="warehouse_area_name"
                                        @input="val => { onChangeWarehouseArea(index, 0, val) }"
                                    />
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="true"
                                        :options="itemReasonOption"
                                        v-model="skuLine.putaway_lines[0].reason"
                                        class="w-full"
                                        label="reason"
                                    ></v-select>
                                </td>
                                <td class="td vs-table--td align-top">
                                    <div class="vx-input-group flex mx-auto">
                                        <vs-button 
                                            @click.stop="addRow(index)" 
                                            size="small" 
                                            color="success"
                                            icon-pack="feather" 
                                            icon="icon-plus" 
                                            title="Add Row"
                                            :disabled="!skuLine.sku_code || skuLine.disabledLine"
                                        />
                                    </div>
                                </td>
                            </tr>

                            <!-- added putaway lines -->
                            <template v-for="(putaways, idx) in skuLinesNoPo[index].putaway_lines">
                                <tr style="padding-bottom: 5px" :key="index + '-' + idx" v-if="idx > 0">
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input
                                            type="number"
                                            v-model="putaways.return_qty"
                                            class="w-full"
                                            :min="0"
                                            :disabled="skuLine.disabledLine"
                                        ></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <v-select
                                            v-if="renderSku"
                                            :filterable="true"
                                            :options="skuLine.option_unit"
                                            v-model="putaways.selected_unit"
                                            label="name"
                                            class="w-full"
                                            :clearable="false"
                                            :disabled="skuLine.disabledLine"
                                            @input="val => { onChangeHU(index, idx, val) }"
                                        ></v-select>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input
                                            type="text"
                                            :value="putaways.price"
                                            disabled
                                            class="w-full"
                                        ></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <v-select
                                            v-if="renderBatch"
                                            :filterable="true"
                                            :options="skuLine.optionBatch"
                                            :value="putaways.batch"
                                            :clearable="false"
                                            class="w-full"
                                            label="batch_external"
                                            @input="val => { onChangeBatch(index, idx, val) }"
                                        />
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <v-select
                                            :filterable="true"
                                            :options="putaways.optionWarehouseArea"
                                            :value="putaways.warehouse_area"
                                            :clearable="false"
                                            class="w-full"
                                            label="warehouse_area_name"
                                            @input="val => { onChangeWarehouseArea(index, idx, val) }"
                                        />
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <v-select
                                            :filterable="true"
                                            :options="itemReasonOption"
                                            v-model="putaways.reason"
                                            class="w-full"
                                            label="reason"
                                        ></v-select>
                                    </td>
                                    <td class="td vs-table--td align-top">
                                        <div class="vx-input-group flex mx-auto">
                                            <vs-button 
                                                v-if="skuLinesNoPo[index].putaway_lines.length > 1"
                                                @click.stop="removeRow(index, idx)"
                                                size="small"
                                                color="danger"
                                                icon-pack="feather"
                                                icon="icon-trash"
                                                style="margin-right: 5px"
                                                title="Remove Row"
                                                :disabled="skuLine.disabledLine"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>

                    <tbody v-if="selectedReturnType.value">
                        <template v-for="(skuLine, index) in skuLines">
                            <tr style="padding-bottom: 5px" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="skuLine.putaway_lines.length">
                                    <div class="vx-input-group flex mx-auto">
                                        <vs-button
                                            @click.stop="removeLine(index)"
                                            size="small"
                                            color="danger"
                                            icon-pack="feather"
                                            icon="icon-trash"
                                            title="Remove Lines"
                                            :disabled="!skuLine.label"
                                        />
                                    </div>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="skuLine.putaway_lines.length">
                                    <vs-textarea
                                        type="text"
                                        :value="skuLine.label"
                                        class="w-full"
                                        disabled
                                        style="word-wrap: break-word;"
                                    ></vs-textarea>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="skuLine.putaway_lines.length">
                                    <vs-input
                                        type="number"
                                        :value="skuLine.qty"
                                        class="w-full"
                                        disabled
                                    ></vs-input>
                                </td>

                                <!-- first putaway lines -->
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input
                                        type="number"
                                        v-model="skuLine.putaway_lines[0].return_qty"
                                        class="w-full"
                                        :min="0"
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <v-select
                                        :filterable="true"
                                        :options="skuLine.option_unit"
                                        v-model="skuLine.putaway_lines[0].selected_unit"
                                        class="w-full"
                                        :clearable="false"
                                        @input="val => { onChangeHU(index, 0, val) }"
                                    ></v-select>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-textarea
                                        type="text"
                                        :value="skuLine.putaway_lines[0].price"
                                        class="w-full"
                                        disabled
                                        style="word-wrap: break-word;"
                                    ></vs-textarea>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-textarea
                                        type="text"
                                        :value="skuLine.putaway_lines[0].batch ? skuLine.putaway_lines[0].batch.label : ''"
                                        disabled
                                        class="w-full"
                                        style="word-wrap: break-word;"
                                    ></vs-textarea>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-textarea
                                        type="text"
                                        :value="skuLine.putaway_lines[0].warehouse_area ? skuLine.putaway_lines[0].warehouse_area.name : ''"
                                        disabled
                                        class="w-full"
                                        style="word-wrap: break-word;"
                                    ></vs-textarea>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <v-select
                                        :filterable="true"
                                        :options="itemReasonOption"
                                        v-model="skuLine.putaway_lines[0].reason"
                                        class="w-full"
                                        label="reason"
                                        :disabled="!skuLine.label"
                                    ></v-select>
                                </td>
                                <td class="td vs-table--td">
                                    <div class="vx-input-group flex mx-auto">
                                        <vs-button 
                                            @click.stop="addRow(index)" 
                                            size="small" 
                                            color="success"
                                            icon-pack="feather" 
                                            icon="icon-plus" 
                                            title="Add Row"
                                            :disabled="!skuLine.label"
                                        />
                                    </div>
                                </td>
                            </tr>

                            <!-- added putaway lines -->
                            <template v-for="(putaways, idx) in skuLines[index].putaway_lines">
                                <tr style="padding-bottom: 5px" :key="index + '-' + idx" v-if="idx > 0">
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-input 
                                            type="number"
                                            v-model="putaways.return_qty"
                                            class="w-full"
                                            :min="0"
                                        ></vs-input>
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <v-select
                                            :filterable="true"
                                            :options="skuLine.option_unit"
                                            v-model="putaways.selected_unit"
                                            class="w-full"
                                            :clearable="false"
                                            @input="val => { onChangeHU(index, idx, val) }"
                                        ></v-select>
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-textarea
                                            type="text"
                                            :value="putaways.price"
                                            class="w-full"
                                            disabled
                                            style="word-wrap: break-word;"
                                        ></vs-textarea>
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-textarea
                                            type="text"
                                            :value="putaways.batch ? putaways.batch.label : ''"
                                            disabled
                                            class="w-full"
                                            style="word-wrap: break-word;"
                                        ></vs-textarea>
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-textarea
                                            type="text"
                                            :value="putaways.warehouse_area ? putaways.warehouse_area.name : ''"
                                            disabled
                                            class="w-full"
                                            style="word-wrap: break-word;"
                                        ></vs-textarea>
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <v-select
                                            :filterable="true"
                                            :options="itemReasonOption"
                                            v-model="putaways.reason"
                                            class="w-full"
                                            label="reason"
                                        ></v-select>
                                    </td>
                                    <td class="td vs-table--td">
                                        <div class="vx-input-group flex mx-auto">
                                            <vs-button 
                                                v-if="skuLines[index].putaway_lines.length > 1"
                                                @click.stop="removeRow(index, idx)"
                                                size="small"
                                                color="danger"
                                                icon-pack="feather"
                                                icon="icon-trash"
                                                style="margin-right: 5px"
                                                title="Remove Row"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit(false)">Save</vs-button>
                    <vs-button class="mr-3 mb-2" @click="handleSubmit(true)" v-if="selectedReturnType.value">Save & Confirm</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import Date from "@/components/Date.vue";
import vSelect from "vue-select";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    components: {
        Date,
        vSelect,
        Datepicker,
        DateRangePicker,
        VueEasyLightbox
    },
    mounted() {
        this.selectedReturnType = this.returnType[0]
        this.handleGetReasonOptions()
        this.handleGetPoOptions()
        this.handleGetSupplierOptions(false)

        this.id = this.$route.params.id;
        this.po = this.$route.params.po;
        if (this.id) {
            this.getData()
            if (this.po == 0) {
                this.selectedReturnType = this.returnType[1]
            }
        }
    },
    data() {
        return {
            id: 0,
            po: 0,
            code: null,
            created_at: null,
            created_by: null,
            documentType: "",
            description: "",
            optionWarehouse: [],
            warehouse: null,
            warehouse_label: "",
            date: moment().toDate(),
            note: "",
            optionPO: [],
            purchase_order_id: 0,
            purchase_order: null,
            optionSupplier: [],
            supplier_id: 0,
            supplier: null,
            optionGR: [],
            gr_number: [],
            optionTax: [],
            tax: null,
            returnType: [
                {
                    value: true,
                    name: "With reference",
                },
                {
                    value: false,
                    name: "Without reference",
                }
            ],
            selectedReturnType: {
                value: false,
                name: "",
            },
            disabledDates: {
                to: moment().subtract(1, "days").toDate()
            },
            grDateRange: [moment().toDate(), moment().add(1, 'days').toDate()],
            itemReasonOption: [],
            skuLines: [
                {
                    purchase_receive_id: 0,
                    purchase_receive_line_id: 0,
                    purchase_order_line_id: 0,
                    sku_code: null,
                    item_name: null,
                    label: null,
                    qty: 0,
                    option_unit: [],
                    putaway_lines: [
                        {
                            return_line_id: 0,
                            putaway_id: 0,
                            selected_unit: null,
                            price: null,
                            rawPrice: 0,
                            return_qty: 0,
                            batch: null,
                            warehouse_area: null,
                            reason: null,
                        }
                    ]
                }
            ],
            indexSku: 0,
            skuLinesNoPo: [
                {
                    disabledLine: false,
                    supplier_return_id: 0,
                    optionSKU: [],
                    sku_code: null,
                    item_name: null,
                    label: null,
                    optionTax: [],
                    skuTax: null,
                    option_unit: [],
                    optionBatch: [],
                    putaway_lines: [
                        {
                            putaway_id: 0,
                            selected_unit: null,
                            price: null,
                            rawPrice: 0,
                            return_qty: 0,
                            batch: null,
                            batch_external: null,
                            optionWarehouseArea: [],
                            warehouse_area: null,
                            reason: null,
                        }
                    ]
                }
            ],
            renderSku: true,
            renderTax: true,
            renderBatch: true,

            // attachments section
            file: null,
            fileUrl: '',
            attachment: "",
            fileAttachment: [],
            IDAttachment: [],
            visible: false,
            images: [],
            index: 0,
        }
    },
    methods: {
        handleSpliceLines(val = false) {
            if (val) {
                this.skuLines.splice(1)
                this.skuLines.forEach((line) => {
                    line.purchase_receive_id = 0
                    line.purchase_receive_line_id = 0
                    line.purchase_order_line_id = 0
                    line.sku_code = null
                    line.item_name = null
                    line.label = null
                    line.qty = 0
                    line.option_unit = []
                    line.putaway_lines.splice(1)
                    line.putaway_lines.forEach((idx) => {
                        idx.return_line_id = 0
                        idx.putaway_id = 0
                        idx.selected_unit = null
                        idx.price = 0
                        idx.return_qty = 0
                        idx.batch = null
                        idx.warehouse_area = null
                        idx.reason = null
                    })
                })
            } else {
                this.skuLinesNoPo.splice(1)
                this.skuLinesNoPo.forEach((line) => {
                    line.disabledLine = false
                    line.supplier_return_id = 0
                    line.sku_code = null
                    line.item_name = null
                    line.label = null
                    line.optionTax = []
                    line.skuTax = null
                    line.option_unit = []
                    line.putaway_lines.splice(1)
                    line.putaway_lines.forEach((idx) => {
                        idx.putaway_id = 0
                        idx.selected_unit = null
                        idx.price = 0
                        idx.return_qty = 0
                        idx.batch = null
                        idx.batch_external = null
                        idx.optionWarehouseArea = []
                        idx.warehouse_area = null
                        idx.reason = null
                    })
                })
                this.indexSku = 0
            }
        },
        handleGetBatch(warehouseID, warehouseAreaId, skuCode, index) {
            let param = {
                warehouse_id: warehouseID,
                warehouse_area_id: warehouseAreaId,
                sku_code: skuCode,
                status: this.documentType,
            }

            this.$http.get(this.$store.state.purchase.supplierReturn.itemStockPath + "/item-batch-ed", { params: param })
            .then((resp) => {
                if (!this.selectedReturnType.value) {
                    if (resp.code == 200) {
                        if (resp.data.records) {
                            this.skuLinesNoPo[index].optionBatch = resp.data.records.map((ob) => ({
                                ...ob,
                                batch_combined: `${ob.batch} / ${ob.batch_external}`
                            })).filter((val, idx, arr) => arr.indexOf(val) === idx);
                            this.renderBatch = false
                            this.$nextTick(() => this.renderBatch = true)
                        } else {
                            this.skuLinesNoPo[index].optionBatch = []
                            this.renderBatch = false
                            this.$nextTick(() => this.renderBatch = true)
                        }
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetPrice(supplierID, warehouseID, skuCode, unit, index, idx) {
            let param = {
                id: this.id,
                supplier_id: supplierID,
                warehouse_id: warehouseID,
                sku_code: skuCode,
                unit: unit,
            }

            this.$http.post(this.$store.state.purchase.supplierReturn.baseUrlPath + "/get-item-price", param)
            .then((resp) => {
                if (this.selectedReturnType.value) {
                    if (resp.code == 200) {
                        this.skuLines[index].putaway_lines[idx].rawPrice = resp.data.item_price
                        this.updateAmount(index, idx, resp.data.item_price)
                    } else {
                        this.skuLines[index].putaway_lines[idx].price = 0
                    }
                } else {
                    if (resp.code == 200) {
                        this.skuLinesNoPo[index].putaway_lines[idx].rawPrice = resp.data.item_price
                        this.updateAmount(index, idx, resp.data.item_price)
                    } else {
                        this.skuLinesNoPo[index].putaway_lines[idx].price = 0
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetReasonOptions() {
            this.$http.get(`${this.$store.state.purchase.poReturn.baseUrlPath}/get-reason-options`)
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.reason_options) {
                        this.itemReasonOption = resp.data.reason_options
                    } else {
                        this.itemReasonOption = []
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetPoOptions() {
            this.$http.get(`${this.$store.state.purchase.poReturn.baseUrlPath}/completed-putaway-po`, {
                params: {
                    order: "id",
                    sort: "asc",
                    limit: 10,
                }
            })
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.records) {
                        this.optionPO = resp.data.records
                    } else {
                        this.optionPO = []
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetSupplierOptions(val = false) {
            if (val) {
                this.$http.get(`${this.$store.state.purchase.poReturn.baseUrlPath}/completed-putaway-supplier`, {
                        params: {
                            order: "id",
                            sort: "asc",
                            limit: 10,
                        }
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        if (resp.data.records) {
                            this.optionSupplier = resp.data.records
                        } else {
                            this.optionSupplier = []
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                });
            } else {
                this.$http.get("/api/wms/v1/wms/purchase/supplier-return/supplier-on-special-area")
                .then((resp) => {
                    if (resp.code == 200) {
                        if (resp.data) {
                            this.optionSupplier = resp.data
                        } else {
                            this.optionSupplier = []
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                });
            }
        },
        handleGetGROptions(po_id){
            this.$http.get("/api/wms/v1/wms/purchase/po-return/document-gr", {
                params: {
                    purchase_order_id: po_id,
                    /* from_date: this.formatDate(dateFrom),
                    to_date: this.formatDate(dateTo), */
                }
            })
            .then((resp) => {
                if (resp.code == 200) {
                    this.optionGR = resp.data
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetTaxOptions(val = false) {
            if (val) {
                this.$http.get(`${this.$store.state.purchase.poReturn.baseUrlPath}/completed-putaway-tax?id=${this.purchase_order_id}&supplier_id=${this.supplier_id}`, {
                        params: {
                            order: "id",
                            sort: "asc",
                            limit: 10,
                        }
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        if (resp.data.default) {
                            this.tax = resp.data.default
                        } else {
                            this.tax = null
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                });
            } else {
                this.$http.get(`${this.$store.state.purchase.poReturn.baseUrlPath}/completed-putaway-tax?id=0&supplier_id=${this.supplier_id}`, {
                        params: {
                            order: "id",
                            sort: "asc",
                            limit: 10,
                        }
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        if (resp.data.records.length) {
                            this.optionTax = resp.data.records
                        } else {
                            this.optionTax = []
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                });
            }
        },
        handleGetTaxOptionsSku(index, skuCode = "") {
            this.$http.get(this.$store.state.purchase.supplierReturn.baseUrlPath + "/get-tax-options", {
                params: {
                    supplier_id: this.supplier_id,
                    sku_code: skuCode,
                }
            })
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data) {
                        this.skuLinesNoPo[index].optionTax = resp.data
                        this.renderTax = false
                        this.$nextTick(() => this.renderTax = true)
                    } else {
                        this.skuLinesNoPo[index].optionTax = []
                        this.skuLinesNoPo[index].skuTax.label = "tax is empty"
                        this.renderTax = false
                        this.$nextTick(() => this.renderTax = true)
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetWarehouseOptions() {
            this.$http.get(`${this.$store.state.purchase.supplierReturn.itemStockPath}/warehouses-on-special-stock?supplier_id=${this.supplier_id}`, {
                params: {
                    order: "id",
                    sort: "asc",
                    limit: 10,
                }
            })
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.records) {
                        this.optionWarehouse = resp.data.records
                    } else {
                        this.optionWarehouse = []
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetSkuOptions(index, supplier_id, warehouse_id, tax_id) {
            this.$http.get(`/api/wms/v1/wms/item-stock/items-on-special-stock?supplier_id=${supplier_id}&warehouse_id=${warehouse_id}&tax_id=${tax_id}`)
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.records) {
                        this.skuLinesNoPo[index].optionSKU = resp.data.records
                        this.renderSku = false
                        this.$nextTick(() => this.renderSku = true)
                    } else {
                        this.skuLinesNoPo[index].optionSKU = []
                        this.renderSku = false
                        this.$nextTick(() => this.renderSku = true)
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetWarehouseAreaOptions(index, idx, skuCode, batchExternal) {
            this.$http.get(this.$store.state.purchase.supplierReturn.baseUrlPath + "/get-warehouse-area-options", {
                params: {
                    warehouse_id: this.warehouse.id,
                    sku_code: skuCode,
                    batch_external: batchExternal,
                    source: this.documentType,
                }
            })
            .then(resp => {
                if (resp.code == 200) {
                    if (resp.data.options) {
                        this.skuLinesNoPo[index].putaway_lines[idx].optionWarehouseArea = resp.data.options
                    }
                } else {
                    this.skuLinesNoPo[index].putaway_lines[idx].optionWarehouseArea = []
                }
            })
        },
        handleBack() {
            this.$router.push({
                name: `${this.$store.state.purchase.poReturn.baseRouterName}.index`,
            });
        },
        getData() {
            if (this.po == 0) {
                this.$vs.loading();
                this.$http.get(this.$store.state.purchase.supplierReturn.baseUrlPath + "/edit-detail/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.id = resp.data.supplier_return.id
                        this.code = resp.data.supplier_return.code
                        this.created_at = resp.data.created_at
                        this.created_by = resp.data.personal.name
                        this.documentType = resp.data.supplier_return.source
                        this.description = resp.data.supplier_return.description
                        this.warehouse = resp.data.warehouse
                        this.warehouse_label = this.warehouse.code+" - "+this.warehouse.name
                        this.date = this.formatDate(resp.data.supplier_return.date)
                        this.note = resp.data.supplier_return.note
                        this.supplier = resp.data.supplier
                        this.supplier_id = resp.data.supplier.id
                        this.handleGetWarehouseOptions()
                        this.skuLinesNoPo = resp.data.sku_lines

                        this.tax = this.skuLinesNoPo[0].skuTax
                        for (let i = 0; i < this.skuLinesNoPo.length; i++) {
                            this.indexSku++
                            this.handleGetSkuOptions(i, this.supplier_id, this.warehouse ? this.warehouse.id : 0, this.tax ? this.tax.id : 0)
                            this.handleGetTaxOptionsSku(i, this.skuLinesNoPo[i].sku_code.sku_code)
                            this.skuLinesNoPo[i].putaway_lines.map((x) => {
                                if (x.batch && x.batch_external) {
                                    let batch = {
                                        batch: x.batch,
                                        batch_external: x.batch_external,
                                        batch_combined: x.batch + " / " + x.batch_external,
                                    }
                                    x.batch = batch;
                                } else {
                                    x.batch = x.batch || "";
                                }
                                return x;
                            });
                            for (let j = 0; j < this.skuLinesNoPo[i].putaway_lines.length; j++) {
                                this.skuLinesNoPo[i].putaway_lines[j].warehouse_area = this.skuLinesNoPo[i].putaway_lines[j].warehouse_area.name
                                this.updateAmount(i, j, this.skuLinesNoPo[i].putaway_lines[j].rawPrice)
                                this.handleGetBatch(this.warehouse.id, this.skuLinesNoPo[i].putaway_lines[j].warehouse_area.id, this.skuLinesNoPo[i].sku_code, i)
                                this.handleGetWarehouseAreaOptions(i, j, this.skuLinesNoPo[i].sku_code, this.skuLinesNoPo[i].putaway_lines[j].batch_external)
                            }

                            if (this.documentType == "import - PO Return Begbal") {
                                this.skuLinesNoPo[i].disabledLine = true
                            }
                        }
                        this.indexSku = this.indexSku - 1

                        if (resp.data.attachment) {
                            resp.data.attachment.forEach((val) => {
                                this.fileAttachment.push({
                                    id: val.id,
                                    NameFile: val.file_name,
                                    PathFile: val.file_url,
                                });
                                this.images.push(val.file_url);
                            });
                        }
                        this.$vs.loading.close();
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$vs.loading.close();
                });
            } else {
                this.$vs.loading();
                this.$http.get("api/wms/v1/wms/purchase/po-return/edit-detail/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.id = resp.data.purchase_return.id
                        this.code = resp.data.purchase_return.code
                        this.created_at = resp.data.created_at
                        this.created_by = resp.data.personal.name
                        this.description = resp.data.purchase_return.description
                        this.warehouse = resp.data.warehouse
                        this.warehouse_label = this.warehouse.code+" - "+this.warehouse.name
                        this.date = this.formatDate(resp.data.purchase_return.date)
                        this.note = resp.data.purchase_return.note
                        this.purchase_order = resp.data.purchase_order
                        this.purchase_order_id = resp.data.purchase_order.id
                        this.supplier = resp.data.supplier
                        this.supplier_id = resp.data.supplier.id
                        this.gr_number = resp.data.gr_data
                        this.tax = resp.data.tax
                        this.skuLines = resp.data.sku_lines

                        for (let i = 0; i < this.skuLines.length; i++) {
                            for (let j = 0; j < this.skuLines[i].putaway_lines.length; j++) {
                                let skuCode = this.skuLines[i].sku_code
                                let itemUnit = this.skuLines[i].putaway_lines[j].selected_unit
                                this.handleGetPrice(this.supplier_id, this.warehouse.id, skuCode, itemUnit, i, j)
                            }
                        }

                        if (resp.data.attachment) {
                            resp.data.attachment.forEach((val) => {
                                this.fileAttachment.push({
                                    id: val.id,
                                    NameFile: val.file_name,
                                    PathFile: val.file_url,
                                });
                                this.images.push(val.file_url);
                            });
                        }

                        this.handleGetGROptions(this.purchase_order_id)

                        this.$vs.loading.close();
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$vs.loading.close();
                });
            }
        },
        onChangeReturnType(val) {
            this.purchase_order = null
            this.purchase_order_id = 0
            this.supplier = null
            this.supplier_id = 0
            this.optionGR = []
            this.gr_number = []
            this.tax = null
            this.warehouse = null
            this.warehouse_label = null

            if (this.selectedReturnType.value) {
                this.handleGetSupplierOptions(true)
                this.handleSpliceLines(false)
            } else {
                this.handleGetSupplierOptions(false)
                this.handleSpliceLines(true)
            }
        },
        onSearchPoNumber(search, loading) {
            if (search.length) {
                loading(true);
                this.searchPoNumber(search, loading, this)
            } else if (search == "") {
                loading(true);
                this.searchPoNumber("", loading, this)
            }
        },
        searchPoNumber: _.debounce(
            (search, loading, t) => {
                t.$http.get(`${t.$store.state.purchase.poReturn.baseUrlPath}/completed-putaway-po`, {
                    params: {
                        order: "id",
                        sort: "asc",
                        query: search,
                        limit: 10,
                    }
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        if (resp.data.records) {
                            t.optionPO = resp.data.records
                            loading(false);
                        } else {
                            t.optionPO = []
                            loading(false);
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                    loading(false);
                });
            }, 1000
        ),
        onChangePoNumber(val) {
            if (val) {
                this.supplier = val.supplier
                this.supplier_id = val.supplier_id
                this.purchase_order_id = val.id
                this.warehouse = val.warehouse
                this.warehouse_label = val.warehouse.code + ' - ' + val.warehouse.name

                /* let dateFrom = this.grDateRange[0]
                let dateTo = this.grDateRange[1] */
                this.optionGR = []
                this.gr_number = []
                this.handleSpliceLines(true)
                this.handleGetSupplierOptions(true)
                this.handleGetTaxOptions(true)
                this.handleGetGROptions(val.id)
            } else if (!val) {
                this.supplier = null
                this.supplier_id = 0
                this.purchase_order = null
                this.purchase_order_id = 0
                this.warehouse = null
                this.warehouse_label = ""
                this.optionSupplier = []
                this.tax = null
                this.optionGR = []
                this.gr_number = []

                this.handleSpliceLines(true)
                this.handleGetSupplierOptions(false)
            }
        },
        onChangeSupplierCode(val) {
            this.supplier_id = val.id
            this.handleGetTaxOptions(false)
            this.handleGetWarehouseOptions()
            this.handleSpliceLines(false)
            this.handleGetSkuOptions(0, this.supplier_id, this.warehouse ? this.warehouse.id : 0, this.tax ? this.tax.id : 0)
        },
        onChangeGRDateRange(val) {
            let dateFrom = val[0]
            let dateTo = val[1]
            this.optionGR = []
            this.gr_number = []
            this.handleSpliceLines(true)

            if (this.purchase_order_id > 0) {
                this.$http
                    .get("/api/wms/v1/wms/purchase/po-return/document-gr", {
                        params: {
                            purchase_order_id: this.purchase_order_id,
                            from_date: this.formatDate(dateFrom),
                            to_date: this.formatDate(dateTo),
                        }
                    })
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.optionGR = resp.data
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }
        },
        onChangeGRNumber(val) {
            if (this.gr_number.length > 0) {
                this.handleSpliceLines(true)

                let purchaseReceiveIDs = []
                this.gr_number.forEach(data => {
                    purchaseReceiveIDs.push(data.id)
                });

                let param = {
                    po_id: this.purchase_order_id,
                    gr_id: purchaseReceiveIDs,
                }

                this.$vs.loading();
                this.$http
                    .post("/api/wms/v1/wms/purchase/po-return/purchase-receive-lines", param)
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.skuLines = resp.data
                            for (let i = 0; i < this.skuLines.length; i++) {
                                for (let j = 0; j < this.skuLines[i].putaway_lines.length; j++) {
                                    this.updateAmount(i, j, this.skuLines[i].putaway_lines[j].price)
                                }
                            }
                            this.$vs.loading.close();
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.$vs.loading.close();
                    });
            } else if (this.gr_number.length == 0) {
                this.handleSpliceLines(true)
            }
        },
        onChangeTax(val) {
            this.handleSpliceLines(false)
            this.handleGetSkuOptions(0, this.supplier_id, this.warehouse ? this.warehouse.id : 0, this.tax ? this.tax.id : 0)
        },
        onChangeTaxSku(idx, val) {
            this.skuLinesNoPo[idx].skuTax = val
        },
        onChangeWarehouse(val) {
            this.warehouse_label = val.code+" - "+val.name
            this.handleSpliceLines(false)
            this.handleGetSkuOptions(0, this.supplier_id, this.warehouse ? this.warehouse.id : 0, this.tax ? this.tax.id : 0)
        },
        onSearchSku(search, loading, index) {
            if (search.length) {
                loading(true);
                this.searchSku(loading, search, this, index, this.supplier_id, this.warehouse ? this.warehouse.id : 0, this.tax ? this.tax.id : 0);
            } else {
                this.handleGetSkuOptions(index, this.supplier_id, this.warehouse ? this.warehouse.id : 0, this.tax ? this.tax.id : 0);
            }
        },
        searchSku: _.debounce(
            (loading, search, t, index, supplier_id, warehouse_id, tax_id) => {
                t.skuLinesNoPo[index].optionSKU = []
                t.$http.get(`/api/wms/v1/wms/item-stock/items-on-special-stock?supplier_id=${supplier_id}&warehouse_id=${warehouse_id}&tax_id=${tax_id}`, {
                    params: {
                        order: "id",
                        sort: "asc",
                        query: search,
                        limit: 10,
                    }
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        if (resp.data.records) {
                            t.skuLinesNoPo[index].optionSKU = resp.data.records
                        } else {
                            t.skuLinesNoPo[index].optionSKU = [];
                        }
                        loading(false);
                    } else {
                        t.skuLinesNoPo[index].optionSKU = [];
                        loading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    loading(false);
                });
            }, 1000
        ),
        onChangeSku(index, val) {
            this.skuLinesNoPo[index].putaway_lines[0].batch = null
            this.skuLinesNoPo[index].putaway_lines[0].price = null
            this.skuLinesNoPo[index].putaway_lines[0].selected_unit = null
            this.skuLinesNoPo[index].putaway_lines.splice(1)

            this.skuLinesNoPo[index].sku_code = val
            let batch = {
                batch: val.batch,
                batch_external: val.batch_external,
                batch_combined: val.batch + " / " + val.batch_external,
            }
            this.skuLinesNoPo[index].putaway_lines[0].batch = batch
            this.skuLinesNoPo[index].putaway_lines[0].warehouse_area = val.warehouse_area.name

            this.$http.get(`/api/wms/v1/master/item-unit/item/${val.id}`)
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.records) {
                        this.skuLinesNoPo[index].option_unit = resp.data.records
                    } else {
                        this.skuLinesNoPo[index].option_unit = []
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });

            this.handleGetBatch(this.warehouse.id, val.warehouse_area_id, val.sku_code, index)
            this.handleGetTaxOptionsSku(index, val.sku_code)
            this.handleGetWarehouseAreaOptions(index, 0, val.sku_code, val.batch_external)
        },
        onChangeHU(index, idx, val) {
            if (this.selectedReturnType.value) {
                this.skuLines[index].putaway_lines[idx].selected_unit = val
                let skuCode = this.skuLines[index].sku_code
                let itemUnit = this.skuLines[index].putaway_lines[idx].selected_unit
                this.handleGetPrice(this.supplier_id, this.warehouse ? this.warehouse.id : 0, skuCode, itemUnit, index, idx)
            } else {
                this.skuLinesNoPo[index].putaway_lines[idx].selected_unit = val
                let skuCode = this.skuLinesNoPo[index].sku_code.sku_code || this.skuLinesNoPo[index].sku_code
                let itemUnit = this.skuLinesNoPo[index].putaway_lines[idx].selected_unit.name || this.skuLinesNoPo[index].putaway_lines[idx].selected_unit
                this.handleGetPrice(this.supplier_id, this.warehouse ? this.warehouse.id : 0, skuCode, itemUnit, index, idx)
            }
        },
        onChangeQty(index, idx, val) {
            if (this.selectedReturnType.value) {
                this.skuLines[index].putaway_lines[idx].return_qty = val
                if (this.skuLines[index].putaway_lines[idx].return_qty > this.skuLines[index].qty) {
                    this.skuLines[index].putaway_lines[idx].return_qty = this.skuLines[index].qty
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Returned Qty cannot be greater than requested qty",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return
                } else if (this.skuLines[index].putaway_lines[idx].return_qty < 0) {
                    this.skuLines[index].putaway_lines[idx].return_qty = 0
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Returned Qty must be greater than zero",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return
                }
            } else {
                this.skuLinesNoPo[index].putaway_lines[idx].return_qty = val
                if (this.skuLinesNoPo[index].putaway_lines[idx].return_qty < 0) {
                    this.skuLinesNoPo[index].putaway_lines[idx].return_qty = 0
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Returned Qty must be greater than zero",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return
                }
            }
        },
        onChangeBatch(index, idx, val) {
            this.skuLinesNoPo[index].putaway_lines[idx].batch = val
            this.handleGetWarehouseAreaOptions(index, idx, this.skuLinesNoPo[index].sku_code.sku_code, val.batch_external)
        },
        onChangeWarehouseArea(index, idx, val) {
            this.skuLinesNoPo[index].putaway_lines[idx].warehouse_area = val.warehouse_area_name
        },
        updateAmount(index, idx, val) {
            if (this.selectedReturnType.value) {
                let cleanValue = val.toString().replace(/\D/g, '');
                let formattedValue = new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 2
                }).format(cleanValue);

                this.skuLines[index].putaway_lines[idx].price = formattedValue;
            } else {
                let cleanValue = val.toString().replace(/\D/g, '');
                let formattedValue = new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 2
                }).format(cleanValue);

                this.skuLinesNoPo[index].putaway_lines[idx].price = formattedValue;
            }
        },
        removeLine(index) {
            if (this.selectedReturnType.value) {
                let draw = 0
                this.skuLines.forEach((val) => {
                    if (val.purchase_receive_id == this.skuLines[index].purchase_receive_id) {
                        draw++
                    }
                })

                if (draw == 1) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Need at least 1 item per GR document to create this document",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }

                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Confirm`,
                    text: "Are you sure delete this lines?",
                    accept: () => {
                        this.skuLines.splice(index, 1)
                    }
                });
            } else {
                if (this.skuLinesNoPo.length == 1) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Need at least 1 item to update this document",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }

                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Confirm`,
                    text: "Are you sure delete this lines?",
                    accept: () => {
                        this.skuLinesNoPo.splice(index, 1)
                    }
                });
            }
        },
        addLine(index) {
            this.skuLinesNoPo.push({
                disabledLine: false,
                supplier_return_id: 0,
                optionSKU: [],
                sku_code: null,
                item_name: null,
                label: null,
                skuTax: null,
                option_unit: [],
                putaway_lines: [
                    {
                        putaway_id: 0,
                        selected_unit: null,
                        price: 0,
                        return_qty: 0,
                        batch: null,
                        batch_external: null,
                        optionWarehouseArea: [],
                        warehouse_area: null,
                        reason: null,
                    }
                ]
            })

            this.indexSku++
            this.handleGetSkuOptions(index+this.indexSku, this.supplier_id, this.warehouse ? this.warehouse.id : 0, this.tax ? this.tax.id : 0)
        },
        removeRow(index, idx) {
            if (this.selectedReturnType.value) {
                this.skuLines[index].putaway_lines.splice(idx, 1)
            } else {
                this.skuLinesNoPo[index].putaway_lines.splice(idx, 1)
            }
        },
        addRow(index) {
            if (this.selectedReturnType.value) {
                this.skuLines[index].putaway_lines.push({
                    return_line_id: 0,
                    putaway_id: 0,
                    selected_unit: null,
                    price: 0,
                    return_qty: 0,
                    batch: this.skuLines[index].putaway_lines[0].batch,
                    warehouse_area: this.skuLines[index].putaway_lines[0].warehouse_area,
                    reason: null,
                })
            } else {
                if (this.id) {
                    this.skuLinesNoPo[index].putaway_lines.push({
                        putaway_id: 0,
                        selected_unit: null,
                        price: 0,
                        return_qty: 0,
                        batch: this.skuLinesNoPo[index].putaway_lines[0].batch,
                        batch_external: this.skuLinesNoPo[index].putaway_lines[0].batch_external,
                        optionWarehouseArea: this.skuLinesNoPo[index].putaway_lines[0].optionWarehouseArea,
                        warehouse_area: this.skuLinesNoPo[index].putaway_lines[0].warehouse_area,
                        reason: null,
    
                        batch_combined: (this.skuLinesNoPo[index].putaway_lines[0].batch || '') + " / " + (this.skuLinesNoPo[index].putaway_lines[0].batch_external || ''),
                    })
                } else {
                    this.skuLinesNoPo[index].putaway_lines.push({
                        putaway_id: 0,
                        selected_unit: null,
                        price: 0,
                        return_qty: 0,
                        batch: this.skuLinesNoPo[index].putaway_lines[0].batch,
                        batch_external: this.skuLinesNoPo[index].putaway_lines[0].batch_external,
                        optionWarehouseArea: this.skuLinesNoPo[index].putaway_lines[0].optionWarehouseArea,
                        warehouse_area: this.skuLinesNoPo[index].putaway_lines[0].warehouse_area,
                        reason: null,
                    })
                }
            }
        },
        handleSubmit(confirm = false) {
            if (confirm) {
                if (!this.fileAttachment.length) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Please add attachment for direct confirm",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }
            }

            if (this.selectedReturnType.value) {
                if (!this.purchase_order) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Please select PO number for reference",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }

                if (this.skuLines.length == 0) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Please select at least 1 GR number to add item lines",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }

                let skus = []
                let message = ""
                this.skuLines.forEach((val) => {
                    let putaways = []
                    val.putaway_lines.forEach((idx) => {
                        if (idx.selected_unit == null) {
                            message = "Please check empty unit, unit is required"
                            return false
                        }

                        if (idx.rawPrice == 0) {
                            message = "Item "+ (val.item_name) +" with "+ idx.selected_unit +" need price information"
                            return false
                        }

                        if (idx.return_qty < 1) {
                            message = "Returned qty minimum is 1"
                            return false
                        }

                        if (idx.reason == null) {
                            message = "Please select reason each lines"
                            return false
                        }

                        putaways.push({
                            return_line_id: idx.return_line_id ? idx.return_line_id : 0,
                            putaway_id: idx.putaway_id ? idx.putaway_id : 0,
                            selected_unit: idx.selected_unit,
                            return_qty: Number(idx.return_qty),
                            batch: idx.batch.batch_external,
                            exp_date: idx.batch.exp_date,
                            warehouse_area_id: idx.warehouse_area.id,
                            reason_id: idx.reason.id,
                        });
                    });

                    skus.push({
                        purchase_receive_id: val.purchase_receive_id,
                        purchase_receive_line_id: val.purchase_receive_line_id,
                        purchase_order_line_id: val.purchase_order_line_id,
                        sku_code: val.sku_code,
                        qty: Number(val.qty),
                        putaway_lines: putaways,
                    });
                });

                if (message != "") {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }

                let purchaseReceiveIDs = []
                this.gr_number.forEach(data => {
                    purchaseReceiveIDs.push(data.id)
                });

                console.log("!!!WITH REFF!!!")
                console.log("sku => ", skus)
                console.log("gr IDs => ", purchaseReceiveIDs)

                const formData = new FormData();
                formData.append("confirm", confirm);
                formData.append("po_id", this.purchase_order_id);
                // formData.append("po_code", this.po.code);
                formData.append("supplier_id", this.supplier_id);
                formData.append("gr_id", JSON.stringify(purchaseReceiveIDs));
                formData.append("tax_id", this.tax.id);
                formData.append("warehouse_id", this.warehouse.id);
                formData.append("description", this.description);
                formData.append("return_date", this.formatDate(this.date));
                formData.append("notes", this.note);
                formData.append("skus", JSON.stringify(skus));
                if (this.fileAttachment.length > 0) {
                    for (let index = 0; index < this.fileAttachment.length; index++) {
                        formData.append("attachments[]", this.fileAttachment[index].File ? this.fileAttachment[index].File : this.fileAttachment[index].id);
                    }
                }

                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Confirm`,
                    text: "Are you sure to submit this form?",
                    accept: () => {
                        this.$vs.loading();
                        this.$http.post("api/wms/v1/wms/purchase/po-return/" + (this.id ? this.id : 0), formData)
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    color: "success",
                                    title: `Data ${this.id ? "Updated" : "Created"}`,
                                    text: `Success Purchase ${this.id ? "update" : "create"} Order Return with code ${resp.data.code}`,
                                    accept: this.handleBack,
                                });
                            } else {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    color: "danger",
                                    title: "Something went wrong",
                                    text: resp.message,
                                });
                            }
                        })
                        .catch(error => {
                            this.$vs.loading.close();
                            console.log(error)
                            this.$vs.dialog({
                                color: "danger",
                                title: "Error",
                                text: error,
                            });
                        });
                    }
                });
            } else {
                let skus = []
                let message = ""
                this.skuLinesNoPo.forEach((val) => {
                    let putaways = []
                    val.putaway_lines.forEach((idx) => {
                        if (idx.selected_unit == null) {
                            message = "Please check empty unit, unit is required"
                            return false
                        }

                        if (idx.rawPrice == 0) {
                            message = "Item "+ (val.sku_code.name) +" with "+ idx.selected_unit.name +" need price information"
                            return false
                        }

                        if (idx.return_qty < 1) {
                            message = "Returned qty minimum is 1"
                            return false
                        }

                        if (idx.batch == null || idx.batch == "") {
                            message = "Please select batch each lines"
                            return false
                        }

                        if (idx.warehouse_area == null || idx.warehouse_area == "") {
                            message = "Please select warehouse area each lines"
                            return false
                        }

                        if (idx.reason.id == 0) {
                            message = "Please select reason each lines"
                            return false
                        }

                        putaways.push({
                            putaway_id: idx.putaway_id ? idx.putaway_id : 0,
                            selected_unit: idx.selected_unit.name || idx.selected_unit,
                            price: Number(idx.rawPrice),
                            return_qty: Number(idx.return_qty),
                            batch: idx.batch.batch_external ? idx.batch.batch_external : idx.batch,
                            warehouse_area: idx.warehouse_area ? idx.warehouse_area.name ? idx.warehouse_area.name : idx.warehouse_area : '',
                            reason_id: idx.reason.id,
                        });
                    });

                    if (!val.skuTax) {
                        message = "Tax is required for each items"
                        return false
                    }

                    skus.push({
                        supplier_return_id: val.supplier_return_id,
                        sku_code: val.sku_code.sku_code || val.sku_code,
                        tax_id: val.skuTax.id,
                        putaway_lines: putaways,
                    });
                });

                if (message != "") {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }

                const formData = new FormData();
                formData.append("confirm", confirm);
                formData.append("supplier_id", this.supplier_id);
                formData.append("warehouse_id", this.warehouse.id);
                formData.append("description", this.description);
                formData.append("return_date", this.formatDate(this.date));
                formData.append("notes", this.note);
                formData.append("skus", JSON.stringify(skus));
                if (this.fileAttachment.length > 0) {
                    for (let index = 0; index < this.fileAttachment.length; index++) {
                        formData.append("attachments[]", this.fileAttachment[index].File ? this.fileAttachment[index].File : this.fileAttachment[index].id);
                    }
                }

                console.log("!!! WITHOUT REFF !!!")
                console.log("isi skus => ", skus)

                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Confirm`,
                    text: "Are you sure to submit this form?",
                    accept: () => {
                        this.$vs.loading();
                        this.$http.post(this.$store.state.purchase.supplierReturn.baseUrlPath + "/update-without-reff/" + (this.id ? this.id : 0), formData)
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    color: "success",
                                    title: `Data ${this.id ? "Updated" : "Created"}`,
                                    text: `Success ${this.id ? "update" : "create"} Supplier Return with code ${resp.data.supplierReturn.code}`,
                                    accept: this.handleBack,
                                });
                            } else {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    color: "danger",
                                    title: "Something went wrong",
                                    text: resp.message,
                                });
                            }
                        })
                        .catch(error => {
                            this.$vs.loading.close();
                            console.log(error)
                            this.$vs.dialog({
                                color: "danger",
                                title: "Error",
                                text: error,
                            });
                        });
                    }
                });
            }
        },

        // attachments section
        download(tr) {
            const link = document.createElement('a');
            link.href = this.urlFile(tr);
            link.download = tr.NameFile;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        onChangeAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    const fileIdx = this.$refs.file.files[i]
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                    this.images.push(URL.createObjectURL(fileIdx));
                }
                document.querySelector("#fileInput").value = "";
            }
        },
        openPreview(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
        handleDeleteAttachment(index) {
            this.fileAttachment.splice(index, 1);
            this.images.splice(index, 1);
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        urlFile: () => {
            return (tr) => {
                return tr.File instanceof Blob ? URL.createObjectURL(tr.File) : tr.PathFile
            }
        }
    },
}
</script>